import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as yup from 'yup'

export const loginSchema = yup.object({
  email: yup.string().required('Введите e-mail').email('Введите валидный e-mail'),
  password: yup.string().required('Введите пароль').min(6, 'Не менее 6 символов')
})

export const restoreSchema = yup.object({
  email: yup.string().required('Введите e-mail').email('Введите валидный e-mail')
})

export const resetPasswordSchema = yup.object({
  password: yup.string().min(6, 'Не менее 6 символов').required('Введите пароль'),
  password_repeat: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать').required('Повторите пароль')
})

export const setRegistrationSchema = (context) => {
  return yup.object({
    email: yup.string().email('Введите валидный e-mail').required('Введите e-mail'),
    phone: yup.lazy((value) => {
      return value ? yup.string().min(context.mask.length, `Не менее ${context.mask.replaceAll(/[^_]/g, '').length} символов`) : yup.string()
    }),
    firstname: yup.string().required('Введите имя'),
    middlename: yup.string(),
    lastname: yup.string().required('Введите фамилию'),
    password: yup.string().min(6, 'Не менее 6 символов').required('Введите пароль')
  })
}

export const setEditUserSchema = (context) => {
  return yup.object({
    firstname: yup.string().required('Введите имя'),
    middlename: yup.string(),
    lastname: yup.string().required('Введите фамилию'),
    phone: yup.lazy((value) => {
      return value ? yup.string().min(context.mask.length, `Не менее ${context.mask.replaceAll(/[^_]/g, '').length} символов`) : yup.string()
    }),
    password: yup.lazy((value) => {
      return value ? yup.string().min(6, 'Не менее 6 символов') : yup.string()
    }),
    password_repeat: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
  })
}
